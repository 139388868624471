import styled from "styled-components/macro";

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from "../../../../util/theme";

const LocationAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
   padding: 30px 30px;
   margin 20px;



  .group {
    display: flex;
    margin-right: auto;
    padding: 20px 50px;
  }

  .star {
    padding-top 5px;
  }

  padding: 5px 0;

  @media (min-width: ${getBreakpoint("lg")}) {
    flex-basis: 33%;

    padding: 15px 0;

    text-align: left;
  }

  .test {
    padding: 0 12px;
  }

  h2 {
    color: #183663;
    font-weight: 800;
    text-align: left !important;
    padding: 0 12px;
  }
  h3 {
    margin: 0;
    color: #183663 !important;
    font-weight: 700;
  }

  h3 {
    color: #e49651;
    font-size: 2.5rem !important;
  }

  p {
    color: ${getColor("black")};
    font-weight: ${getFontWeight("light")};

    margin: 0;
    padding: 5px 0 0 0;

    @media (min-width: ${getBreakpoint("lg")}) {
      padding: 5px 0 0 0;
    }

    &.small {
      font-size: 1.4rem;
    }

    a {
      color: ${getColor("black")};
      font-weight: ${getFontWeight("light")};
      text-decoration: none;

      @media (min-width: ${getBreakpoint("lg")}) {
        font-size: 2.4rem;
      }

      &:hover {
        color: ${getColor("accentGreen")};
      }
    }
  }
`;

export default LocationAddress;
