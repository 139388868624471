// importing from 'styled-components/macro' doesn't work for this component for some reason
// well it WORKS but it doesn't apply the styles properly?
import styled from 'styled-components';

export const Field = styled.div`
  margin: 10px 0;

  position: relative;
`;

export default Field;
