import styled from "styled-components/macro";

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from "../../../../util/theme";

const AboutContainer = styled.section`
  background-color: #f4f5f6;
  padding: 45px 20px !important;

  @media (min-width: ${getBreakpoint("lg")}) {
    padding: 50px 0 10px 0;
  }

  @media (min-width: ${getBreakpoint("md")}) {
    padding: 45px 200px !important;
  }

  p {
    color: ${getColor("black")};
    font-size: 1.9rem;
    font-weight: ${getFontWeight("light")};
    line-height: 1.4;
    margin-top: 0;
    padding: 6px;
  }

  img {
    max-width: 100%;

    @media (min-width: ${getBreakpoint("lg")}) {
      float: left;

      padding: 0 25px 0 0;

      max-width: 500px;
    }
  }
`;

export default AboutContainer;
