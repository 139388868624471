import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const CalcResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${getBreakpoint("lg")}) {
    flex-direction: ${(props) => (props.column ? "column" : "row")};
  }

  div {
    padding: 0 25px;

    @media (min-width: ${getBreakpoint("lg")}) {
      padding: 0 50px 0 0;
    }
  }

  h4,
  h5,
  p {
    margin: 0.25rem 0;
    font-weight: ${getFontWeight("regular")};
  }

  h4,
  p {
    color: ${getColor("white")};
  }

  h4,
  h5 {
    text-shadow: 2px 2px 3px ${getColor("black")};
  }

  h4 {
    font-size: 2.25rem;
  }

  h5 {
    color: ${getColor("white")};
    font-size: 2rem;

    padding: 10px 0;
  }
`;

export default CalcResultsContainer;
