import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const CalcField = styled.div`
  position: relative;

  flex-basis: 100%;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-basis: 33%;
  }
`;

export default CalcField;
