import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const ReviewsRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
    @media (min-width: ${getBreakpoint('lg')}) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export default ReviewsRow;
