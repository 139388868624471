import styled from 'styled-components/macro';

const CalcWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 50px 0;
`;

export default CalcWrapper;
