import React, { PureComponent } from "react";
import { loader } from "graphql.macro";

// import components
import BranchSingle from "./BranchSingle";

// load graphql query
const GET_BRANCHES_QUERY = loader("../graphql/GET_BRANCHES_QUERY.graphql");

class App extends PureComponent {
  render() {
    const path = "/montana";
    return (
      <BranchSingle key={path} path={`/${path}`} id={"a2A4M000002aiLMUAY"} />
    );
  }
}

export default App;
