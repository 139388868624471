import React from "react";
import { Query } from "react-apollo";
import { loader } from "graphql.macro";

// import components
import Loading from "../Loading";
import Header from "./Header";
import Nav from "./Nav";
import About from "./About";
import Team from "./Team";
import ContactForm from "./ContactForm";
import Reviews from "./Reviews";
import HbGuide from "./HbGuide";
import Calculators from "./Calculators";
import Location from "./Location";
import Footer from "./Footer";
import TradLoanPrograms from "./TradLoanPrograms";
import PortLoanPrograms from "./PortLoanPrograms";

// import styled components
import { Grid } from "../styled";

const GET_BRANCH_QUERY = loader("../../graphql/GET_BRANCH_QUERY.graphql");

const BranchSingle = ({ id, path, client }) => {
  console.log("BranchSingle id: ", id);
  if (path === "/Schumacher") {
    return <h1>Schumacher</h1>;
  }
  return (
    <Query asyncMode query={GET_BRANCH_QUERY} variables={{ id }}>
      {({ data, error, loading }) => {
        if (loading) return <Loading />;

        if (error) {
          console.log(error);
          return `Error: ${error}`;
        }

        const {
          branch: {
            address,
            coordinates,
            description,
            googleAddress,
            members,
            name,
            owner,
            photoUrl,
            sectionImageUrl,
            telephoneNumber,
            localCallNumber,
          },
        } = data;

        return (
          <>
            <Header backgroundImage={photoUrl} name={name} id={id} />
            <Nav />
            <main>
              <About
                description={description}
                sectionImage={sectionImageUrl}
                name={name}
              />
              <Grid>
                <Team members={members} />
              </Grid>
              <ContactForm />
              <TradLoanPrograms />
              <PortLoanPrograms />
              <Reviews />
              <HbGuide name={name} account={owner.account} />
              <Calculators />
              <Location
                address={address}
                googleAddress={googleAddress}
                coordinates={coordinates}
                name={name}
                telephoneNumber={telephoneNumber}
              />
              <Footer />
            </main>
          </>
        );
      }}
    </Query>
  );
};

export default BranchSingle;
