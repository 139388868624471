import styled from 'styled-components/macro';

// import styled components
import { Grid } from '../../../styled';

const ReviewsContainer = styled(Grid)`
  padding: 0;
h2 {
    font-weight: bold;
  }
`;



export default ReviewsContainer;
