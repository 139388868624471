import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const CalcFieldRow = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 50px;

  @supports (display: grid) {
    display: Grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;

    padding: 0;

    @supports (display: grid) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export default CalcFieldRow;
