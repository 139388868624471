import React from "react";

// import styled components
import {
  FooterBottom,
  FooterContainer,
  FooterTop,
  FooterWrapper,
} from "./styled";

// import assets
import ehl from "../../../assets/othl/ehl.png";

const Footer = () => (
  <FooterWrapper>
    <FooterContainer>
      <FooterTop>
        <a
          href="https://www.nmlsconsumeraccess.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          NMLS Consumer Access
        </a>
        <a
          href="https://onetrusthomeloans.com/licensing-information/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Locations and Licensing
        </a>
        <a
          href="https://onetrusthomeloans.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </a>
        {/* <a
          href="https://www.onetrusthomeloans.com/cookie-information-opt-out/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Cookie Information & Opt-Out
        </a> */}
        <a
          href="https://onetrusthomeloans.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          © {new Date().getFullYear()} OneTrust Home Loans
        </a>
      </FooterTop>
      <FooterBottom>
        <img src={ehl} alt="Equal Housing Lender" />
        <p>
          OneTrust Home Loans is the trade name of the legal entity, CalCon
          Mutual Mortgage LLC, a Delaware LLC (NMLS #46375). The OneTrust name
          has been registered in most states where CalCon operates, and in those
          states where it is not registered, we operate as CalCon. Equal Housing
          Lender.
          <br />
          <br />
          Corporate NMLS #46375 (
          <a
            href="http://www.nmlsconsumeraccess.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.nmlsconsumeraccess.org
          </a>
          )
          <br />
          3838 Camino del Rio N Suite 305, San Diego, CA 92108. Corporate phone
          (888) 488-3807
        </p>
      </FooterBottom>
    </FooterContainer>
  </FooterWrapper>
);

export default Footer;
