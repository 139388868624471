import styled from 'styled-components/macro';

const HbGuideWrapper = styled.div`
  img {
    width: 100%;
  }

  padding: 0 0 75px 0;
`;

export default HbGuideWrapper;
