import styled from 'styled-components/macro';

// import assets
import calcBackground from '../../../../assets/banner/calc-background.jpg';

const CalcSectionWrapper = styled.div`
  background-image: url(${calcBackground});
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default CalcSectionWrapper;
