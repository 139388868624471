import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const HbGuideSubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-basis: 40%;
  }
`;

export default HbGuideSubmitWrapper;
