import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

export const CalcTitle = styled.h3`
  padding: 0 25px 25px 25px;

  @media (min-width: ${getBreakpoint('lg')}) {
    padding: auto;
  }
`;

export default CalcTitle;
