import React, { useState, useEffect } from "react";

// import assets
import ConventionalIcon from "../../../assets/loanPrograms/Conventional_Home_Loans.png";
import FHAIcon from "../../../assets/loanPrograms/FHA_Home_Loans.png";
import VAIcon from "../../../assets/loanPrograms/VA_Home_Loans.png";
import USDAIcon from "../../../assets/loanPrograms/USDA_Home_Loans.png";
import JumboIcon from "../../../assets/loanPrograms/Jumbo_Financing.png";
import ReverseIcon from "../../../assets/loanPrograms/Reverse.png";

//Traditional Loan Programs

const index = ({ program, title }) => {
  const loanPrograms = [
    {
      imageUrl: ConventionalIcon,
      title: "Conventional Home Loans",
      description: "Traditional loan programs with low down payment options.",
    },
    {
      imageUrl: FHAIcon,
      title: "FHA Home Loans",
      description:
        "Backed by the Federal Housing Administration, the FHA Loan Program can make it easier for first time homebuyers to purchase a home. ",
    },
    {
      imageUrl: VAIcon,
      title: "VA Home Loans",
      description:
        "Insured by the VA, this loan program was created so the heroes of our country could have the opportunity to buy a home or refinance. ",
    },
    {
      imageUrl: USDAIcon,
      title: "USDA Home Loans",
      description:
        "Backed by the U.S. Department of Agriculture, this program gives those in eligible rural areas with credit challenges or limited income the opportunity to buy a home with no money down. ",
    },
    {
      imageUrl: JumboIcon,
      title: "Jumbo Financing",
      description:
        "Buy the home of your dreams with just 10% down and no private mortgage insurance. ",
    },
    {
      imageUrl: ReverseIcon,
      title: "Reverse Mortgage",
      description:
        "Available to homeowners who are 62 years of age or older, this financing option allows homeowners to borrow money against the equity in their home.",
    },
  ];
  return (
    <div className="mx-auto">
      <div>
        <h2 className=" font-bold text-center pt-16 pb-10">
          Traditional Loan Programs
        </h2>
      </div>

      <div className="grid lg:grid-cols-3 gap-2">
        {loanPrograms.map((loan, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-4 px-24 my-12"
          >
            <img
              src={loan.imageUrl}
              alt={loan.title}
              className="h-auto w-[150px] mb-6"
            />
            <h2 className="text-4xl font-bold mb-6 text-center">
              {loan.title}
            </h2>
            <p className="text-md text-center md:w-3/4">{loan.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default index;
