import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../util/theme';

const LoadingWrapper = styled.main`
  background-color: ${getColor('primaryBlue')};

  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    color: ${getColor('accentGreen')};
  }

  svg:last-of-type {
    width: 60vw;

    fill: ${getColor('white')};

    padding: 75px 0;

    @media (min-width: ${getBreakpoint('lg')}) {
      width: 35vw;
    }
  }
`;

export default LoadingWrapper;
