import React from 'react';

// import components
import NavItem from './NavItem';

// import styled components
import { Grid } from '../../styled';
import { NavContainer } from './styled';

const Nav = () => (
  <NavContainer role="navigation">
    <Grid>
      <ul>
        <NavItem target="about" label="About" />
        <NavItem target="team" label="Meet the Team" />
        <NavItem target="reviews" label="Reviews" />
        <NavItem target="hbguide" label="Homebuyer's Guide" />
        <NavItem target="calculators" label="Calculators" />
        <NavItem target="location" label="Location" />
      </ul>
    </Grid>
  </NavContainer>
);

export default Nav;
