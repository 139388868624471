import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const RefinanceRow = styled.div`
  display: flex;
  flex-direction: column;

  @supports (display: Grid) {
    display: Grid;
  }

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;

    @supports (display: Grid) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 25px;
    }
  }
`;

export default RefinanceRow;
