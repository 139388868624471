import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const NavItemContainer = styled.li`
  flex: auto;

  a {
    color: ${getColor('white')};
    font-size: 2rem;
    font-weight: ${getFontWeight('thin')};
    text-align: center;
    text-decoration: none;

    display: block;

    padding: 1rem 0.5rem;

    @media (min-width: ${getBreakpoint('lg')}) {
      padding: 2rem 1rem;
    }

    &:hover {
      background-color: ${getColor('accentGreen')};
      color: ${getColor('white')};
    }
  }
`;

export default NavItemContainer;
