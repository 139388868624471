import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const CalcSectionButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  padding: 25px;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-wrap: nowrap;
    align-items: flex-start;

    padding: 25px 0;
  }
`;

export default CalcSectionButtonsWrapper;
