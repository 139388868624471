const refinanceSaving = [
  {
    name: 'originalInterestRate',
    label: 'Original Interest Rate',
    config: {
      allowNegative: false,
      decimalScale: 2,
      fixedDecimalScale: true,
      isNumericString: true,
      suffix: ' %',
    },
  },
  {
    name: 'originalLoanAmount',
    label: 'Origin Loan Amount',
    config: {
      allowNegative: false,
      isNumericString: true,
      prefix: '$',
      thousandSeparator: true,
    },
  },
  {
    name: 'originalLoanTerm',
    label: 'Original Loan Term',
    config: {
      allowNegative: false,
      isNumericString: true,
      suffix: ' years',
    },
  },
  {
    name: 'timeLeft',
    label: 'Time Left to Pay on Original Loan',
    config: {
      allowNegative: false,
      isNumericString: true,
      suffix: ' months',
    },
  },
  {
    name: 'newInterestRate',
    label: 'New Interest Rate',
    config: {
      allowNegative: false,
      decimalScale: 2,
      fixedDecimalScale: true,
      isNumericString: true,
      suffix: ' %',
    },
  },
  {
    name: 'newLoanAmount',
    label: 'New Loan Amount',
    config: {
      allowNegative: false,
      isNumericString: true,
      prefix: '$',
      thousandSeparator: true,
    },
  },
  {
    name: 'newLoanTerm',
    label: 'New Loan Term',
    config: {
      allowNegative: false,
      isNumericString: true,
      suffix: ' years',
    },
  },
];

export default refinanceSaving;
