const hbgFormData = [
  {
    name: 'firstName',
    type: 'text',
    label: 'First Name',
    validators: ['alphaNumericValidator'],
    submitError: 'Please enter your first name using only letters, numbers, spaces, and dashes',
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'Last Name',
    validators: ['alphaNumericValidator'],
    submitError: 'Please enter your last name using only letters, numbers, spaces, and dashes',
  },
  // email field is set to type text so it's marked as valid when anything is entered
  // still won't submit if it doesn't pass validation, this is done so the label shifts when something is entered and doesn't reset
  {
    name: 'email',
    type: 'type',
    label: 'Email Address',
    validators: ['emailValidator'],
    submitError: 'Please enter your email address in a valid format',
    errorStyle: { color: 'red', fontSize: '12px' },
  },
];

export default hbgFormData;
