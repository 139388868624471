import styled from "styled-components/macro";

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from "../../../../util/theme";

const CalcSectionButton = styled.button`
  background-color: ${getColor("complimentaryBlue")};

  border: 0;
  outline: 0;

  color: ${getColor("white")};
  font-family: Roboto, sans-serif;
  font-size: 1.9rem;
  font-weight: ${getFontWeight("bold")};

  padding: 15px 20px;
  margin: 5px;

  height: 140px;
  width: 140px;

  cursor: pointer;

  @media (min-width: ${getBreakpoint("lg")}) {
    margin: 0 20px 0 0;

    height: auto;
    width: auto;
  }

  &:hover {
    background-color: ${getColor("accentGreen")};
  }

  &.active {
    background-color: ${getColor("primaryBlue")};
  }

  &:focus {
    outline: 1px solid ${getColor("white")};
  }
`;

export default CalcSectionButton;
