import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const HbGuideFieldsWrapper = styled.div`
  padding: 0 50px !important;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-basis: 60%;
  }
`;

export default HbGuideFieldsWrapper;
