import React, { PureComponent } from 'react';

// import field data
import { refinanceSaving } from './data';

// import components
import {
  CalcTitle,
  CalcFieldRow,
  CalcField,
  CalcError,
  CalcInput,
  CalcLabel,
  CalcWrapper,
  CalcButton,
  CalcResultsWrapper,
  CalcResultsContainer,
  RefinanceRow,
  RefinanceTotal,
} from './styled';

class RefinanceSaving extends PureComponent {
  state = {
    values: {
      originalInterestRate: '',
      originalLoanAmount: '',
      originalLoanTerm: '',
      timeLeft: '',
      newInterestRate: '',
      newLoanAmount: '',
      newLoanTerm: '',
    },
    errors: {
      originalInterestRate: '',
      originalLoanAmount: '',
      originalLoanTerm: '',
      timeLeft: '',
      newInterestRate: '',
      newLoanAmount: '',
      newLoanTerm: '',
    },
    oldValues: {
      monthlyPayment: '',
      remainingBalance: '',
      remainingTotal: '',
    },
    newValues: {
      newMonthlyPayment: '',
      monthlySavings: '',
      totalCost: '',
    },
    totalSavings: '',
    calculated: false,
  };

  drawFields = fields => {
    return fields.map(field => {
      const { config, label, name, validators } = field;
      return (
        <CalcField key={name}>
          <CalcInput
            {...config}
            type="text"
            aria-labelledby={name}
            name={name}
            id={name}
            value={this.state.values[name]}
            onChange={e => this.handleChange(e, validators)}
            required
          />
          <CalcLabel htmlFor={name}>{label}</CalcLabel>
          {this.state.errors[name] ? (
            <CalcError className="error">{this.state.errors[name]}</CalcError>
          ) : (
            <CalcError>&nbsp;</CalcError>
          )}
        </CalcField>
      );
    });
  };

  handleChange = (e, validators) => {
    const { name, value } = e.target;

    const updateObj = { ...this.state.values };

    updateObj[name] = value;

    this.setState({ values: updateObj });
  };

  calculateSaving = () => {
    const {
      values: {
        originalInterestRate,
        originalLoanAmount,
        originalLoanTerm,
        timeLeft,
        newInterestRate,
        newLoanAmount,
        newLoanTerm,
      },
    } = this.state;

    const oldValues = { ...this.state.oldValues };
    const newValues = { ...this.state.newValues };

    const monthsLeft = timeLeft.replace(' months', '');

    const originalPrincipal = originalLoanAmount.replace('$', '').replace(/,/g, '');
    const originalRate = originalInterestRate.replace('%', '').replace(/ /g, '') / 100 / 12;
    const originalTerm = originalLoanTerm.replace(' years', '') * 12;

    const originalNumerator = originalRate * Math.pow(1 + originalRate, originalTerm);
    const originalDenominator = Math.pow(1 + originalRate, originalTerm) - 1;
    const originalPayment = originalPrincipal * (originalNumerator / originalDenominator);

    const monthsPaid = originalTerm - monthsLeft;
    let remainingBalance = originalPrincipal;

    for (let i = 0; i < monthsPaid; i++) {
      remainingBalance = remainingBalance * (1 + originalRate) - originalPayment;
    }

    oldValues.monthlyPayment = originalPayment
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    oldValues.remainingBalance = remainingBalance
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    oldValues.remainingTotal = (originalPayment * monthsLeft).toFixed(2);

    const newPrincipal = newLoanAmount.replace('$', '').replace(/,/g, '');
    const newRate = newInterestRate.replace('%', '').replace(/ /g, '') / 100 / 12;
    const newTerm = newLoanTerm.replace(' years', '') * 12;

    const newNumerator = newRate * Math.pow(1 + newRate, newTerm);
    const newDenominator = Math.pow(1 + newRate, newTerm) - 1;
    const newPayment = newPrincipal * (newNumerator / newDenominator);

    newValues.newMonthlyPayment = newPayment
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    newValues.monthlySavings = Math.abs(newPayment - originalPayment)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    newValues.totalCost = (newPayment * newLoanTerm.replace(' years', '') * 12).toFixed(2);

    const totalSavings = (oldValues.remainingTotal - newValues.totalCost)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    oldValues.remainingTotal = oldValues.remainingTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    newValues.totalCost = newValues.totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.setState({
      oldValues,
      newValues,
      totalSavings,
      calculated: true,
    });

    return;
  };

  render() {
    const {
      oldValues: { monthlyPayment, remainingBalance, remainingTotal },
      newValues: { newMonthlyPayment, monthlySavings, totalCost },
      totalSavings,
      calculated,
    } = this.state;
    return (
      <>
        <CalcTitle>Estimate How Much You Will Save By Refinancing</CalcTitle>
        <CalcFieldRow>{this.drawFields(refinanceSaving)}</CalcFieldRow>
        <CalcWrapper>
          <CalcButton onClick={() => this.calculateSaving()}>Calculate</CalcButton>
        </CalcWrapper>
        {calculated ? (
          <CalcResultsWrapper>
            <CalcResultsContainer column={true}>
              <h3>Old Loan</h3>
              <RefinanceRow>
                <div>
                  <h4>Monthly Payment</h4>
                  <h5>${monthlyPayment}</h5>
                </div>
                <div>
                  <h4>Remaining Loan Balance</h4>
                  <h5>${remainingBalance}</h5>
                </div>
                <div>
                  <h4>Remaining Total Cost</h4>
                  <h5>${remainingTotal}</h5>
                </div>
              </RefinanceRow>
              <h3>New Loan</h3>
              <RefinanceRow>
                <div>
                  <h4>Monthly Payment</h4>
                  <h5>${newMonthlyPayment}</h5>
                </div>
                <div>
                  <h4>Remaining Loan Balance</h4>
                  <h5>${monthlySavings}</h5>
                </div>
                <div>
                  <h4>Remaining Total Cost</h4>
                  <h5>${totalCost}</h5>
                </div>
              </RefinanceRow>
              <RefinanceTotal>
                <h4>Total Savings</h4>
                <h5>${totalSavings}</h5>
              </RefinanceTotal>
            </CalcResultsContainer>
          </CalcResultsWrapper>
        ) : null}
      </>
    );
  }
}

export default RefinanceSaving;
