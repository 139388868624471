import React, { useState, useEffect } from "react";

// import assets
import BridgeIcon from "../../../assets/loanPrograms/Bridge_Home_Loan_Program.png";
import FaFIcon from "../../../assets/loanPrograms/Fix_and_flip.png";
import BSLPIcon from "../../../assets/loanPrograms/Bank_Statement.png";
import ConstructionIcon from "../../../assets/loanPrograms/Construction_Home_Loans.png";
import LaLIcon from "../../../assets/loanPrograms/Lot_Land_Loans.png";
import CondoIcon from "../../../assets/loanPrograms/Condo_Hotel_Financing.png";

//Portfolio Loan Programs

const index = ({ program, title }) => {
  const loanPrograms = [
    {
      imageUrl: BridgeIcon,
      title: "Bridge Home Loan Program",
      description:
        "Our specialized program allows you to buy your new home and worry about selling your old home later. Qualify for a new home loan, avoid two mortgage payments, and sidestep the extra costs of temporary housing. ",
    },
    {
      imageUrl: FaFIcon,
      title: "Fix & Flip Loan Program",
      description:
        "This short-term business loan helps you to purchase and rehab a home. Interest-only options are available with limited documentation required.",
    },
    {
      imageUrl: BSLPIcon,
      title: "Bank Statement Loan Program",
      description:
        "If you don’t work the standard nine to five, we’ll consider your business and personal bank statements in lieu of W-2’s or regular payroll checks. ",
    },
    {
      imageUrl: ConstructionIcon,
      title: "Construction Home Loans",
      description:
        "Construction loans allow you many options: build a home on the land you already own or buy the land and build a home with just one mortgage. ",
    },
    {
      imageUrl: LaLIcon,
      title: "Lot & Land Loans ",
      description:
        "Finance your lot or land purchase to build your dream home.",
    },
    {
      imageUrl: CondoIcon,
      title: "Condo Hotel Financing ",
      description:
        "Finance a second home or investment property with resort-like amenities.",
    },
  ];
  return (
    <div className="mx-auto bg-gray-100 md:px-24">
      <div>
        <h2 className=" font-bold text-center pt-16 pb-10">
          Portfolio Loan Programs
        </h2>
      </div>

      <div className="grid lg:grid-cols-3 gap-2 ">
        {loanPrograms.map((loan, index) => (
          <div
            key={index}
            className="flex flex-col items-center p-4 px-24 my-12"
          >
            <img
              src={loan.imageUrl}
              alt={loan.title}
              className="h-auto w-[150px] mb-6"
            />
            <h2 className="text-4xl font-bold mb-6 text-center">
              {loan.title}
            </h2>
            <p className="text-md text-center md:w-3/4">{loan.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default index;
