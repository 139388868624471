import styled from 'styled-components/macro';

// import helpers
import { getColor } from '../../../../util/theme';

const FooterWrapper = styled.div`
  background-color: ${getColor('black')};

  padding: 25px 0 125px 0;
`;

export default FooterWrapper;
