import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  background: linear-gradient(180deg, #183663 0%, #274879 50%, #94bd4f 100%);

  min-height: 650px;
`;

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 66%;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    width: 100%;
    margin: 0 auto;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  justify-content: center;
  item-align: center;
  margin: 0 auto;
  padding: 3em 0;
`;

const Label = styled.label`
  color: white;
  font-size: 2rem;
  font-weight: 100;
  text-align: left;
`;

const Input = styled.input`
  background: transparent;
  border: 1px solid white;
  border-radius: 8px;
  padding: 0.2em 1.5em 0.2em 0.8rem;
  width: 100%;
  color: white;
  font-size: 2rem;

  ${(props) =>
    props.error &&
    `
    border-color: red;
  `}
`;

const ErrorMessage = styled.p`
  color: red;
`;

const SubmitButton = styled.button`
  background: #821b56;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5em 1em;
  border-radius: 5px;
  width: 12em;
  margin-top: 1em;
`;

export default function ContactMe({ currentUser }) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    errors: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      city: "",
      state: "",
    },
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showNotificationModal, setNotificationModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [isError, setIsError] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let validationErrors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      city: "",
      state: "",
    };

    if (!form.firstName) {
      validationErrors.firstName = "First Name is required";
    }

    if (!form.lastName) {
      validationErrors.lastName = "Last Name is required";
    }

    if (!emailValidation(form.email)) {
      validationErrors.email = "Invalid Email";
    }

    // if (!onlyAllowNumbers(form.phoneNumber)) {
    //   validationErrors.phoneNumber = "Phone Number must be numeric";
    // }

    if (!form.phoneNumber) {
      validationErrors.phoneNumber = "Phone Number is required";
    }

    if (Object.values(validationErrors).some((error) => error !== "")) {
      // Errors were found. Do not submit the form and set the errors in state
      setErrors(validationErrors);
      setIsLoading(false);
      return; // Stop here and do not submit the form
    }

    const dataToSend = {
      ...form,
      source: "OneTrust Montana Branch Page - Contact Form",
    };

    fetch("https://hooks.zapier.com/hooks/catch/5841802/39897vt/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },

      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setNotificationModal(true);
        setModalData({
          title: "Success!",
          message: "Your message has been sent.",
        });
        setForm({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          city: "",
          state: "",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setNotificationModal(true);
        setModalData({
          title: "Error!",
          message: "There was an error sending your message.",
        });
      });
  };

  // Helper functions to validate fields
  const emailValidation = (email) => {
    let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const onlyAllowNumbers = (str) => {
    let regex = /^[0-9\b]+$/;
    return regex.test(str);
  };

  const handlePhoneChange = (e) => {
    // format parentheses, dashes, and spaces
    // only allow numbers
    let input = e.target.value;
    input = input.replace(/[^0-9]/g, "");
    if (input.length > 3) {
      input = `(${input.substring(0, 3)}) ${input.substring(3)}`;
    }
    if (input.length > 9) {
      input = `${input.substring(0, 9)}-${input.substring(9)}`;
    }
    input = input.substring(0, 14);

    setForm({
      ...form,
      [e.target.name]: input,
    });
  };

  return (
    <Container>
      <div>
        <h2 className=" font-bold text-center text-white pt-16 pb-10">
          Get in Touch!
        </h2>
      </div>
      <FormContainer onSubmit={handleSubmit}>
        {/* First Name */}
        <InputGroup>
          <Label>First Name</Label>
          <Input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            error={errors.firstName}
          />
          {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
        </InputGroup>
        <InputGroup>
          <Label>Last Name</Label>
          <Input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            error={errors.lastName}
          />
          {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
        </InputGroup>{" "}
        <InputGroup>
          <Label>Email Address</Label>
          <Input
            type="text"
            name="email"
            value={form.email}
            onChange={handleChange}
            error={errors.email}
          />
          {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        </InputGroup>{" "}
        <InputGroup>
          <Label>Phone Number</Label>
          <Input
            type="text"
            name="phoneNumber"
            value={form.phoneNumber}
            onChange={handlePhoneChange}
            error={errors.phoneNumber}
          />
          {errors.phoneNumber && (
            <ErrorMessage>{errors.phoneNumber}</ErrorMessage>
          )}
        </InputGroup>
      </FormContainer>
      <div className="flex justify-center mt-4">
        {!isLoading && (
          <SubmitButton onClick={handleSubmit} type="submit">
            Submit
          </SubmitButton>
        )}
        {isLoading && (
          <div className="flex justify-center">
            {/* <Spinner width="100px" /> */}
          </div>
        )}
      </div>
      {showNotificationModal && (
        <div className=" flex items-center justify-center mt-10">
          <div className="bg-white bg-opacity-60 rounded-lg shadow-lg p-5 md:p-8 md:w-1/4 text-white space-y-4">
            <h2 className="text-3xl font-bold text-center">Success!</h2>
            <p className="text-center text-black">
              Your message has been sent.
            </p>
          </div>
        </div>
      )}

      <p className="text-sm px-14 py-12 text-white font-thin mx-58 text-center md:w-1/2 mx-auto">
        By clicking the 'Submit' button below, I authorize OneTrust Home Loans
        to contact me about a mortgage by a live agent, artificial or
        prerecorded voice, and SMS text at my residential or cellular number,
        dialed manually or by autodialer and to be contacted via email, even if
        the telephone number is a cellular phone or other service for which the
        party is charged. I understand that I am not required to provide this
        consent in order to obtain goods or services from OneTrust Home Loans. I
        agree to receive disclosures and communications in electronic form and I
        confirm that I have a working computer system and an internet website
        browser to view this webpage and this loan inquiry form. I agree that{" "}
        OneTrust Home Loans may contact me by mail, telephone or email in
        connection with my request even if my telephone number of email address
        appears on a OneTrust Home Loans internal Do Not Call / Do Not Email
        List, a State or National Do Not Call Registry, or Do Not E-Mail list,
        or any other Do Not Contact List.{" "}
      </p>
    </Container>
  );
}
