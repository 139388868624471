import { createGlobalStyle } from "styled-components/macro";

import { getBreakpoint, getColor, getFontWeight } from "../../util/theme";

const GlobalStyles = createGlobalStyle`
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  * {
        box-sizing: border-box;
    }

    html {
      font-size: 10px;
    }

    html, body {
        font-family: 'Roboto', sans-serif;
        line-height: 1.4

        margin: 0;
        padding: 0;
    }

    h1 {
     
      font-size: 3rem;

      @media (min-width: ${getBreakpoint("lg")}) {
          font-size: 4rem;
      }
    }

    h2 {
        color: ${getColor("primaryBlue")};
        font-size: 2.75rem;

        @media (min-width: ${getBreakpoint("lg")}) {
          font-size: 3.75rem;
      }
    }

    h3 {
        color: ${getColor("accentGreen")};
        font-size: 2.25rem;
    }

    a {
        color: ${getColor("primaryBlue")};
        font-weight: ${getFontWeight("medium")};

        &:hover {
            color: ${getColor("accentGreen")};
        }
    }

    a, p {
        font-size: 1.6rem;
    }

    img {
        max-width: 100%;
    }

    .modal-open {
      overflow: hidden;
    }
`;

export default GlobalStyles;
