import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }
`;

export default LocationWrapper;
