import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const TeamMember = styled.div`
  text-align: center;

  padding: 25px 0;

  flex-basis: 100%;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-basis: 33%;
  }

  img {
    border-radius: 50%;
  }

  h2 {
  }

  h3 {
    color: ${getColor('black')};

    margin: 10px 0 0 0;
  }

  p {
    margin: 0;
  }

  a,
  p {
    font-size: 1.6rem;
  }

  a {
    display: inline-block;

    color: ${getColor('white')};
    font-weight: ${getFontWeight('regular')};
    text-decoration: none;

    background-color: ${getColor('primaryBlue')};

    margin: 20px 0;
    padding: 5px 10px;

    &:hover {
      background-color: ${getColor('accentGreen')};

      color: ${getColor('white')};

      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
  }
`;

export default TeamMember;
