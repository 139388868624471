import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const CalcResultsWrapper = styled.div`
  border-top: 1px dotted ${getColor('white')};

  h3 {
    color: ${getColor('white')};
    font-weight: ${getFontWeight('regular')};
  }
`;

export default CalcResultsWrapper;
