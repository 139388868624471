import styled from 'styled-components/macro';
import NumberFormat from 'react-number-format';

// import theme helpers
import { getColor } from '../../../../util/theme';

const CalcInput = styled(NumberFormat)`
  background-color: transparent;

  border: 0;
  border-bottom: 1px solid ${getColor('white')};
  box-shadow: 0 2px 1px -1px transparent;

  caret-color: ${getColor('accentGreen')};

  color: ${getColor('white')};
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;

  display: block;

  width: 100%;

  padding: 10px 2px;

  transition: all 0.3s linear;

  &:hover,
  &:focus {
    border-bottom: 1px solid ${getColor('white')};
  }

  &:focus,
  &:valid {
    ~ label {
      color: ${getColor('white')};

      transform: translate3d(0, -60px, 0) scale(0.95);
    }
  }
`;

export default CalcInput;
