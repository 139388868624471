import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getBreakpoint, getFontWeight } from '../../../../util/theme';

const CalcError = styled.p`
  color: ${getColor('white')};
  font-size: 1.3rem;
  font-weight: ${getFontWeight('bold')};

  margin: 0;

  position: absolute;
  right: 0px;
  bottom: ${props => (props.bottom ? props.bottom : '2px')};

  max-width: 200px;

  @media (min-width: ${getBreakpoint('lg')}) {
    right: 50px;
  }

  &.error {
    background-color: ${getColor('accentRed')};

    padding: 5px;

    &:before {
      height: 0;
      width: 0;

      border-color: transparent ${getColor('accentRed')} transparent transparent;
      border-style: solid;
      border-width: 5px 10px 5px 0;

      position: absolute;
      left: -10px;
      top: 10px;

      content: '';
    }
  }
`;

export default CalcError;
