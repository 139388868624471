import styled from "styled-components/macro";

// import assets
import quoteLeft from "../../../../assets/img/quote-left.svg";
import quoteRight from "../../../../assets/img/quote-right.svg";

// import theme helpers
import { getBreakpoint, getColor } from "../../../../util/theme";

const ReviewsEntry = styled.div`
  border: 10px solid ${getColor("white")};
  #reviewBody {
    margin-top: 20px;
  }

  color: ${getColor("white")};
  font-family: "Crimson Text", serif;
  font-size: 16px;
  line-height: 1.3;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin: 0 25px 50px 25px;
  padding: 30px 30px 50px 30px !important;

  position: relative;

  @media (min-width: ${getBreakpoint("lg")}) {
    flex-basis: 33%;

    margin: 0 25px;
  }

  &:before,
  &:after {
    background-color: ${getColor("primaryBlue")};
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 70px;

    content: "";

    display: block;

    height: 100px;
    width: 100px;

    position: absolute;
  }

  &:before {
    background-image: url(${quoteLeft});

    top: -55px;
    left: 20px;
  }

  &:after {
    background-image: url(${quoteRight});

    right: 20px;
    bottom: -55px;
  }

  a {
    color: ${getColor("white")};

    &:hover {
      color: ${getColor("accentGreen")};
    }
  }

  div {
    text-align: right;

    span,
    p {
      margin: 5px 0;
    }

    span {
      color: ${getColor("accentGreen")};
      font-size: 24px;
    }
  }
`;

export default ReviewsEntry;
