import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const HbGuideSubmit = styled.button`
  background-color: ${getColor('primaryBlue')};

  border: 0;

  color: ${getColor('white')};
  font-size: 2rem;

  margin: 0 auto 10px auto;
  padding: 10px 0;

  width: 50%;

  cursor: pointer;

  &:hover {
    background-color: ${getColor('accentGreen')};
  }
`;

export default HbGuideSubmit;
