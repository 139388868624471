import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  img {
    width: 50px;
    height: 50px;
  }

  a,
  p {
    color: ${getColor('white')};
  }

  p {
    font-size: 1.4rem;
    font-weight: ${getFontWeight('thin')};

    padding: 0 0 0 25px;
  }

  a {
    font-weight: ${getFontWeight('regular')};

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default FooterBottom;
