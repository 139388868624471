import styled from 'styled-components/macro';

// import styled components
import { Grid } from '../../../styled';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const HbGuideContainer = styled(Grid)`
  text-align: center;

  @media (min-width: ${getBreakpoint('lg')}) {
    text-align: left;
  }

  h2 {
    padding: 50px 25px 0 25px;
    font-weight: bold;
  }
`;

export default HbGuideContainer;
