import React from 'react';

// import components
import { ReactComponent as OthlLogo } from "../../assets/othl/othl-logo-white.svg";

// import styled components
import { Spinner } from '../styled';
import { LoadingWrapper } from './styled';

const Loading = () => (
  <LoadingWrapper>
    <Spinner height="200px" width="200px" />
    <h1>
      <em>Loading your branch...</em>
    </h1>
    <OthlLogo />
  </LoadingWrapper>
);

export default Loading;
