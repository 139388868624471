// importing from 'styled-components/macro' doesn't work for this component for some reason
// well it WORKS but it doesn't apply the styles properly?
import styled from 'styled-components';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../util/theme';

const Error = styled.p`
  color: ${getColor('white')};
  font-size: 1.3rem;
  font-weight: ${getFontWeight('bold')};

  margin: 0;

  position: absolute;
  right: 0px;
  bottom: ${props => (props.bottom ? props.bottom : '2px')};

  max-width: 200px;

  @media (min-width: ${getBreakpoint('lg')}) {
    right: 5px;
  }

  &.error {
    background-color: ${getColor('accentRed')};

    padding: 5px;

    &:before {
      height: 0;
      width: 0;

      border-color: transparent ${getColor('accentRed')} transparent transparent;
      border-style: solid;
      border-width: 5px 10px 5px 0;

      position: absolute;
      left: -10px;
      top: 10px;

      content: '';
    }
  }
`;

export default Error;
