import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from '../../../../util/theme';

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 25px 0;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }

  a {
    color: ${getColor('white')};
    font-size: 1.4rem;
    font-weight: ${getFontWeight('thin')};
    text-decoration: none;

    padding: 10px 0;

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default FooterTop;
