import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const RefinanceTotal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 50px 0 0 0;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-basis: 33%;
  }
`;

export default RefinanceTotal;
