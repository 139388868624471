// importing from 'styled-components/macro' doesn't work for this component for some reason
// well it WORKS but it doesn't apply the styles properly?
import styled from 'styled-components';

// import theme helpers
import { getColor, getFontWeight } from '../../../util/theme';

// import assets
import caretDown from '../../../assets/img/caret-down.png';

const Select = styled.select`
  background-color: ${getColor('complimentaryBlue')};

  color: ${getColor('white')};
  font-size: 16px;
  font-weight: ${getFontWeight('regular')};

  border: 0;

  height: 30px;
  width: 100%;

  padding: 0 0 0 10px;

  appearance: none;

  background-image: url(${caretDown});
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;

  &:active,
  &:focus {
    border: 1px solid ${getColor('accentGreen')};
  }
`;

export default Select;
