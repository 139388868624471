const theme = {
  // OneTrust colors, mostly
  colors: {
    primaryBlue: "#183663",
    primaryBlueRgb: "4, 52, 100",
    complimentaryBlue: "#5481a3",
    accentGreen: "#9BC24D",
    accentOrange: "#eab345",
    accentRed: "#fd5f54",
    black: "#323232",
    white: "#ffffff", // not official OneTrust
    grey: "#4d4d4d", // not official OneTrust
    lightGrey: "#e3e3e3", // not official OneTrust
    offGreen: "#819a3f", // not official OneTrust
  },
  breakpoints: {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1800px",
  },
  // height breakpoints for MLO laptops that have squishy 1366 x 768 resolution
  heightpoints: {
    mlo: "768px",
  },
  // typography styles - for keeping sizes consistent
  typography: {
    // correspond with Google font actual weights for Roboto
    weights: {
      thin: "100",
      light: "300",
      regular: "400",
      medium: "500",
      bold: "700",
      black: "900",
    },
  },
};

// helper functions to ease using theme - components can import just the helpers they need and easily access the theme with them
export const getBreakpoint = (breakpoint) => (props) =>
  props.theme.breakpoints[breakpoint];

export const getColor = (color) => (props) => props.theme.colors[color];

export const getFontWeight = (weight) => (props) =>
  props.theme.typography.weights[weight];

export const getHeightpoint = (heightpoint) => (props) =>
  props.theme.heightpoints[heightpoint];

export default theme;
