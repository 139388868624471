import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const TeamMemberHeadshot = styled.div`
  background-image: url(${props => props.backgroundImage});
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  border: 2px solid ${getColor('accentGreen')};
  border-radius: 50%;

  margin: 0 auto;

  height: 220px;
  width: 220px;
`;

export default TeamMemberHeadshot;
