import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getBreakpoint } from '../../../../util/theme';

// import styled components
import { Grid } from '../../../styled';

const CalcSectionContainer = styled(Grid)`
  min-height: 700px;

  padding: 50px 0;

  text-align: center;

  @media (min-width: ${getBreakpoint("lg")}) {
    text-align: left;
  }

  h2 {
    font-weight: bold;
  }

  h2,
  h3 {
    color: ${getColor("white")};
    text-shadow: 2px 2px 1px ${getColor("primaryBlue")};
  }
`;

export default CalcSectionContainer;
