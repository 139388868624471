import React from 'react';

// import styled components
import { HeaderContainer } from './styled';

// import assets
import OTHLLogo from "../../../assets/othl/othl-logo-white.svg";

const Header = ({ backgroundImage, name }) => (
  <HeaderContainer
    backgroundImage={backgroundImage}
    id="nav-header"
    role="banner"
  >
    <a
      href="https://onetrusthomeloans.com/"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="onetrusthomeloans.com/"
    >
      <img
        src={OTHLLogo}
        alt="OTHL Logo"
        style={{ width: "330px", paddingTop: "30px", paddingLeft: "45px" }}
      />
    </a>
    <h1>Your Local Montana Mortgage Lender</h1>
  </HeaderContainer>
);

export default Header;
