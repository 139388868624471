import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';

const Map = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={14} defaultCenter={props.coordinates}>
      {props.isMarkerShown && (
        <Marker position={props.coordinates}>
          <InfoWindow>
            <a
              href={`https://www.google.com/maps/dir//${props.googleAddress}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Directions
            </a>
          </InfoWindow>
        </Marker>
      )}
    </GoogleMap>
  ))
);

export default Map;
