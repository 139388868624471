import styled from "styled-components/macro";

// import theme helpers
import { getBreakpoint, getColor, getFontWeight } from "../../../../util/theme";

const HeaderContainer = styled.div`
  background-color: ${getColor("primaryBlue")};
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  height: 400px;

  position: relative;

  svg {
    display: block;

    fill: ${getColor("white")};

    height: auto;
    width: 330px;

    position: absolute;
    left: 0%;
    top: 1%;
    

    &:hover {
      fill: ${getColor("accentGreen")};
    }
  }

  h1 {
    background-color: rgba(255, 255, 255, 0.75);

    color: ${getColor("black")};
    font-weight: ${getFontWeight("bold")};
    text-align: center;

    display: inline-block;

    padding: 0 25px;

    position: absolute;
    bottom: 10px;

    @media (min-width: ${getBreakpoint("lg")}) {
      text-align: left;

      bottom: auto;

      left: 50px;
      top: 75%;
    }
  }
`;

export default HeaderContainer;
