import React from "react";

// import styled components
import {
  TeamContainer,
  TeamMember,
  TeamMemberHeadshot,
  TeamWrapper,
} from "./styled";

// import assets
import profilePlaceholder from "../../../assets/img/profile-placeholder.svg";

const Team = (props) => (
  // sort members by title
  props.members.sort((a, b) => {
    if (a.title === "Branch Manager") return -1;
    if (b.title === "Branch Manager") return 1;
    return 0; // Keep the original order for other titles
  }),
  console.log(props),
  (
    <TeamWrapper id="nav-team">
      <h2>Meet the Team</h2>

      <TeamContainer>
        {props.members.map((member) => {
          // pull out individual member data pieces
          const { firstName, lastName, account, title, nmlsNumber, mloPage } =
            member;

          // has to be a let as it can be reassigned if no profile picture exists
          let { headshot } = member;

          if (!headshot) {
            headshot = profilePlaceholder;
          }

          if (mloPage === true) {
            return (
              <TeamMember key={nmlsNumber}>
                <TeamMemberHeadshot backgroundImage={headshot} />
                <h3>{`${firstName} ${lastName}`}</h3>
                <p>
                  <em>
                    {title}
                    <br />
                    {`NMLS# ${nmlsNumber}`}
                  </em>
                </p>
                <a
                  aria-label={`${firstName}'s site`}
                  href={`https://onetrusthomeloans.com/contact/${account}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Visit My Site
                </a>
              </TeamMember>
            );
          }
          return null;
        })}
      </TeamContainer>
    </TeamWrapper>
  )
);

export default Team;
