// importing from 'styled-components/macro' doesn't work for this component for some reason
// well it WORKS but it doesn't apply the styles properly?
import styled from 'styled-components';

// import theme helpers
import { getColor, getFontWeight } from '../../../util/theme';

const Label = styled.label`
  color: ${props => (props.color ? props.color : getColor('primaryBlue'))};
  font-size: 1.6rem !important;
  font-weight: ${getFontWeight('regular')};

  padding: 0;

  display: inline-block;

  transform: translate3d(0, -34px, 0) scale(1);
  transition: 0.3s all ease;

  pointer-events: none;
`;

export default Label;
