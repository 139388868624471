import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const CalcButton = styled.button`
  background-color: ${getColor('primaryBlue')};

  border: 0;

  color: ${getColor('white')};
  font-size: 2rem;

  margin: 0 auto 10px auto;
  padding: 10px 0;

  width: 40%;

  cursor: pointer;

  @media (min-width: ${getBreakpoint('lg')}) {
    width: 20%;
  }

  &:hover {
    background-color: ${getColor('accentGreen')};
  }
`;

export default CalcButton;
