import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getBreakpoint } from '../../../../util/theme';

const NavContainer = styled.div`
  background-color: ${getColor("primaryBlue")};

  border-bottom: 2px solid ${getColor("accentGreen")};

  width: 100%;

  ul {
    color: ${getColor("complimentaryBlue")};

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    list-style: none;

    margin: 0 auto;
    padding: 0;

    max-width: 100%;

    @media (min-width: ${getBreakpoint("lg")}) {
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
`;

export default NavContainer;



 // border-bottom: 2px solid ${getColor('accentGreen')};