import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const ReviewsWrapper = styled.div`
  background-color: ${getColor('primaryBlue')};

  padding: 25px 0 75px 0;

  text-align: center;

  @media (min-width: ${getBreakpoint('lg')}) {
    text-align: left;
  }

  h2 {
    color: ${getColor('white')};

    padding: 0 25px 50px 25px;
  }
`;

export default ReviewsWrapper;
