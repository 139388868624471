import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const HbGuideFormRow = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0 0 25px 0;

  @media (min-width: ${getBreakpoint('lg')}) {
    flex-direction: row;
  }
`;

export default HbGuideFormRow;
