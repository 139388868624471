import styled from 'styled-components/macro';

// import theme helpers
import { getColor } from '../../../../util/theme';

const CalcLabel = styled.label`
  color: ${getColor('white')};
  font-size: 1.6rem;

  display: inline-block;

  transform: translate3d(0, -34px, 0) scale(1);
  transition: 0.3s all ease;

  pointer-events: none;
`;

export default CalcLabel;
