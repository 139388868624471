import React, { PureComponent } from "react";
import { Mutation } from "react-apollo";
import { loader } from "graphql.macro";

// import config
import { THANK_YOU_PAGE_URL } from "../../../util/config";

// import validators
import {
  alphaNumericValidator,
  emailValidator,
} from "../../../util/validators";

// import data
import { hbgFormData } from "./data";

// import styled components
import { Spinner } from "../../styled";
import { Field, Error, Input, Label } from "../styled";
import {
  HbGuideFieldsWrapper,
  HbGuideFormRow,
  HbGuideSubmit,
  HbGuideSubmitWrapper,
  HbGuideSuccessWrapper,
} from "./styled";

// load graphql mutation
const CREATE_LOANFORCE_CONTACT_MUTATION = loader(
  "../../../graphql/CREATE_LOANFORCE_CONTACT_MUTATION.graphql"
);

class HbGuideForm extends PureComponent {
  constructor() {
    super();

    this.alphaNumericValidator = alphaNumericValidator.bind(this);
    this.emailValidator = emailValidator.bind(this);
  }

  state = {
    values: { firstName: "", lastName: "", email: "" },
    errors: { firstName: "", lastName: "", email: "" },
    submitErrors: { firstName: "", lastName: "", email: "" },
    formIsValid: false,
    isFormSubmitted: false,
  };

  componentDidMount() {
    const submitErrors = { ...this.state.submitErrors };
    const submitKeys = Object.keys(submitErrors).map((key) => {
      return key;
    });

    hbgFormData.map((field) => {
      submitKeys.map((key) => {
        if (field.name === key) {
          submitErrors[key] = field.submitError;
        }
        return null;
      });
      return null;
    });

    this.setState({
      submitErrors,
    });
  }

  drawFields = (fields) => {
    return fields.map((field) => {
      const { name, type, label, validators } = field;
      return (
        <Field key={name}>
          <Input
            aria-labelledby={name}
            type={type}
            name={name}
            id={`${name}-hbguide`}
            value={this.state.values[name]}
            onChange={(e) => this.handleChange(e, validators)}
            required
          />
          <Label htmlFor={`${name}-hbguide`}>{label}</Label>
          {this.state.errors[name] ? (
            <Error className="error" bottom="-20px">
              {this.state.errors[name]}
            </Error>
          ) : (
            <Error>&nbsp;</Error>
          )}
        </Field>
      );
    });
  };

  handleChange = (e, validators) => {
    const { name, value } = e.target;

    const updateObj = { ...this.state.values };

    updateObj[name] = value;

    validators.forEach((validator) => {
      this[validator](value, name);
    });

    this.setState({ values: updateObj });
  };

  checkIfValid = async () => {
    const { values, errors, submitErrors } = this.state;

    let isValid = true;

    await Object.keys(values).map((key) => {
      if (values[key].length === 0) {
        isValid = false;
        errors[key] = submitErrors[key];
      }
      return null;
    });

    if (isValid) {
      this.setState({
        formIsValid: true,
      });
    } else {
      this.setState({ errors });
    }
  };

  render() {
    const pdf =
      "https://res.cloudinary.com/dqbckxsgm/image/upload/v1686336517/guides/Ultimate%20Home%20Buyers%20Guide.pdf";
    if (this.state.isFormSubmitted) {
      return (
        <HbGuideSuccessWrapper>
          <h3 className="pt-24">Thank you for your request!</h3>
          <a
            href={pdf}
            target="_blank"
            rel="noopener noreferrer"
            className="text-center hover:text-blue-primary"
          >
            <span className="underline">Click here</span> to download your copy
            of the Ultimate Guide to Buying Your Dream Home.
          </a>
        </HbGuideSuccessWrapper>
      );
    }
    const { firstName, lastName, email } = this.state.values;

    return (
      <HbGuideFormRow>
        <Mutation mutation={CREATE_LOANFORCE_CONTACT_MUTATION}>
          {(createLoanForceContact, { data, error, loading }) => {
            if (data) {
              // setTimeout(() => {
              //   window.location.href = THANK_YOU_PAGE_URL;
              // }, 1000);

              return (
                <HbGuideSuccessWrapper>
                  <h3 className="pt-24">Thank you for your request!</h3>
                  <a
                    href={pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-center hover:text-blue-primary"
                  >
                    <span className="underline">Click here</span> to download
                    your copy of the Ultimate Guide to Buying Your Dream Home.
                  </a>
                </HbGuideSuccessWrapper>
              );
            }

            if (loading) {
              return (
                <HbGuideSuccessWrapper>
                  <Spinner />
                </HbGuideSuccessWrapper>
              );
            }

            if (error) {
              return (
                <HbGuideSuccessWrapper>
                  <h3>We're sorry!</h3>
                  <p>
                    An error was encountered with your submission - please
                    refresh the page and try again.
                  </p>
                </HbGuideSuccessWrapper>
              );
            }

            return (
              <>
                <HbGuideFieldsWrapper>
                  {this.drawFields(hbgFormData)}
                </HbGuideFieldsWrapper>
                <HbGuideSubmitWrapper>
                  <HbGuideSubmit
                    onClick={async () => {
                      await this.checkIfValid();

                      if (this.state.formIsValid) {
                        const dataToSend = {
                          firstName,
                          lastName,
                          email,
                          campaignName:
                            "Montana Team - Branch Website - HB Guide",
                          source: "OneTrust Montana Branch Page - HB Guide",
                          campaignStatus: "Active",
                          emailCampaignCode: "a0tPo000001Bz4PIAS",
                        };

                        fetch(
                          "https://hooks.zapier.com/hooks/catch/5841802/39897vt/",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              "Content-Type":
                                "application/x-www-form-urlencoded",
                            },

                            body: JSON.stringify(dataToSend),
                          }
                        )
                          .then((res) => {
                            res.json();
                          })
                          .then((data) => {
                            console.log(data);
                            this.setState({ isFormSubmitted: true }); // <-- Add this line
                            const pdf =
                              "https://res.cloudinary.com/dqbckxsgm/image/upload/v1686336517/guides/Ultimate%20Home%20Buyers%20Guide.pdf";

                            window.open(pdf, "_blank");
                            return data;
                          });
                      }
                    }}
                  >
                    Download Now
                  </HbGuideSubmit>
                </HbGuideSubmitWrapper>
              </>
            );
          }}
        </Mutation>
      </HbGuideFormRow>
    );
  }
}

export default HbGuideForm;
