import styled from "styled-components/macro";

// import theme helpers
import { getBreakpoint } from "../../../../util/theme";

const LocationMap = styled.div`
  @media (min-width: ${getBreakpoint("lg")}) {
    flex-basis: 66%;
  }

  background-color: #183663 !important;
  

  .duh {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    margin-block: 30px;
    width: 85%;
    }

  @media (min-width: ${getBreakpoint("md")}) {
     padding: 70px 0px 0px 0px;
     min-height: 575px;

      .duh {
        margin-top: 60px;
      }
}
@media (min-width: ${getBreakpoint("xxl")}) {
     padding: 0px 0px 0px 0px;
     img {
      width: 70%;
}
`;

export default LocationMap;
