// importing from 'styled-components/macro' doesn't work for this component for some reason
// well it WORKS but it doesn't apply the styles properly?
import styled from 'styled-components';

// import theme helpers
import { getColor } from '../../../util/theme';

const Input = styled.input`
  background-color: transparent;

  border: 0;
  border-bottom: 3px solid ${getColor('complimentaryBlue')};
  box-shadow: 0 2px 1px -1px transparent;

  caret-color: ${getColor('accentGreen')};

  color: ${getColor('primaryBlue')};
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;

  display: block;

  width: 100%;

  padding: 10px 2px;

  transition: all 0.3s linear;

  &:hover,
  &:focus {
    border-bottom: 3px solid ${getColor('primaryBlue')};
  }

  &:focus,
  &:valid {
    ~ label {
      color: ${getColor('primaryBlue')};

      transform: translate3d(0, -60px, 0) scale(0.95);
    }
  }
`;

export default Input;
