import React from "react";
import ReactHtmlParser from "react-html-parser";

// import styled components
import { AboutContainer } from "./styled";

const About = ({ description, name, sectionImage }) => (
  <AboutContainer id="nav-about">
    {sectionImage ? <img src={sectionImage} alt={`OneTrust ${name}`} /> : null}
    {ReactHtmlParser(description)}
  </AboutContainer>
);

export default About;
