const mortgagePayment = [
  {
    name: 'loanAmount',
    label: 'Total Home Loan Amount',
    config: {
      allowNegative: false,
      isNumericString: true,
      prefix: '$',
      thousandSeparator: true,
    },
  },
  {
    name: 'interestRate',
    label: 'Annual Interest Rate',
    config: {
      allowNegative: false,
      decimalScale: 2,
      fixedDecimalScale: true,
      isNumericString: true,
      suffix: ' %',
    },
  },
  {
    name: 'loanTerm',
    label: 'Term of the Loan',
    config: {
      allowNegative: false,
      isNumericString: true,
      suffix: ' years',
    },
  },
];

export default mortgagePayment;
