import "react-app-polyfill/ie9"; // For IE 9-11 support
import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";
import theme from "./util/theme";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";
import "./index.css";

// import config
import { LIVE_GRAPHQL_API_URL as GRAPHQL_API_URL } from "./util/config";
// import { DEV_GRAPHQL_API_URL as GRAPHQL_API_URL } from "./util/config";
// import { TEST_GRAPHQL_API_URL as GRAPHQL_API_URL } from './util/config';

// import components
import App from "./components/App";

// import styled components
import { GlobalStyles } from "./components/styled";


const client = new ApolloClient({
  uri: GRAPHQL_API_URL,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <ApolloProvider client={client}>
      <GlobalStyles />
      <App />
    </ApolloProvider>
  </ThemeProvider>,
  document.getElementById("root")
);
