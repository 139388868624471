import React from 'react';
// importing from 'styled-components/macro' doesn't work for this component for some reason
import styled from 'styled-components';

// import helpers
import { getColor } from '../../util/theme';

const Spinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 50px auto 50px auto;
  height: ${props => (props.height ? props.height : `100px`)}
  width: ${props => (props.width ? props.width : `100px`)}
  & .path {
    stroke: ${getColor('accentGreen')};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default props => (
  <Spinner height={props.height} width={props.width} viewBox="0 0 50 50">
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="4" />
  </Spinner>
);
