import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

// import styled components
import { NavItemContainer } from './styled';

const NavItem = props => (
  <NavItemContainer>
    <AnchorLink href={`#nav-${props.target}`}>{props.label}</AnchorLink>
  </NavItemContainer>
);

export default NavItem;
