import React from "react";

// import assets
import hbGuideBanner from "../../../assets/banner/hbguide-banner-2x.jpg";
import hbGuideMobile from "../../../assets/banner/hbguide-banner-mobile.jpg";

// import components
import HbGuideForm from "./HbGuideForm";

// import styled components
import { HbGuideContainer, HbGuideWrapper } from "./styled";

function HbGuide({ account, name }) {
  let bannerToShow;

  if (window.outerWidth) {
    if (window.outerWidth >= 992) {
      bannerToShow = hbGuideBanner;
    } else {
      bannerToShow = hbGuideBanner;
    }
  } else {
    if (document.body.clientWidth >= 992) {
      bannerToShow = hbGuideBanner;
    } else {
      bannerToShow = hbGuideBanner;
    }
  }

  return (
    <HbGuideWrapper id="nav-hbguide">
      <img src={bannerToShow} alt="Homebuyer's Guide" />
      <HbGuideContainer>
        <h2>Get Your Copy of the Ultimate Home Buyer's Guide</h2>
        <HbGuideForm name={name} account={account} />
      </HbGuideContainer>
    </HbGuideWrapper>
  );
}

export default HbGuide;
