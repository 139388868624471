import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

// import styled components
import { Grid } from '../../../styled';

const FooterContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 25px;

  @media (min-width: ${getBreakpoint('lg')}) {
    padding: 0 75px;
  }
`;

export default FooterContainer;
