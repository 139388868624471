import React from "react";
import ReactHtmlParser from "react-html-parser";
import MAP_IMAGE from "../../../assets/img/Map2.jpg";

// import components
import Map from "../Map";

// import styled components
import { LocationAddress, LocationMap, LocationWrapper } from "./styled";

function Location({
  address,
  coordinates,
  googleAddress,
  name,
  telephoneNumber,
}) {
  const formattedCoordinates = (coordinates) => {
    const newCoordinates = { lat: 0, lng: 0 };

    newCoordinates.lat = parseFloat(coordinates.lat);
    newCoordinates.lng = parseFloat(coordinates.lng);

    return newCoordinates;
  };

  const parsedAddress = (address) => {
    return address.replace(",", "<br />");
  };

  return (
    <LocationWrapper id="nav-location">
      <LocationAddress>
        <h2>Our Locations</h2>
        <div class="group">
          <div class="star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="red"
            >
              <polygon points="25,1 31,18 50,18 34,29 40,46 25,36 10,46 16,29 1,18 20,18" />
            </svg>
          </div>
          <div className="test">
            <h3>Bozeman</h3>
            <p>
              <a>
                1716 W. Main St, Ste 8F, <br />
                Bozeman, MT 59715
              </a>
            </p>
          </div>
        </div>
        <div class="group">
          <div class="star">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="red"
            >
              <polygon points="25,1 31,18 50,18 34,29 40,46 25,36 10,46 16,29 1,18 20,18" />
            </svg>
          </div>
          <div className="test">
            <h3>Kalispell</h3>
            <p>
              <a>
                1205 S. Main St, Suite #102, <br />
                Kalispell, MT 59901
              </a>
            </p>
          </div>
        </div>
      </LocationAddress>
      <LocationMap>
        <div class="duh">
          <img src={MAP_IMAGE}  />
        </div>
      </LocationMap>
    </LocationWrapper>
  );
}

export default Location;
