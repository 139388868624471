import React, { PureComponent } from 'react';

// import field data
import { affordBorrow } from './data';

// import styled components
import {
  CalcTitle,
  CalcFieldRow,
  CalcField,
  CalcError,
  CalcInput,
  CalcLabel,
  CalcWrapper,
  CalcButton,
  CalcResultsWrapper,
  CalcResultsContainer,
} from './styled';

class AffordBorrow extends PureComponent {
  state = {
    values: { interestRate: '', loanTerm: '', tax: '', insurance: '', income: '', debt: '' },
    errors: { interestRate: '', loanTerm: '', tax: '', insurance: '', income: '', debt: '' },
    maximumAmount: 0,
    maximumMonthly: 0,
    calculated: false,
  };

  drawFields = fields => {
    return fields.map(field => {
      const { config, label, name, validators } = field;
      return (
        <CalcField key={name}>
          <CalcInput
            {...config}
            type="text"
            aria-labelledby={name}
            name={name}
            id={name}
            value={this.state.values[name]}
            onChange={e => this.handleChange(e, validators)}
            required
          />
          <CalcLabel htmlFor={name}>{label}</CalcLabel>
          {this.state.errors[name] ? (
            <CalcError className="error">{this.state.errors[name]}</CalcError>
          ) : (
            <CalcError>&nbsp;</CalcError>
          )}
        </CalcField>
      );
    });
  };

  handleChange = (e, validators) => {
    const { name, value } = e.target;

    const updateObj = { ...this.state.values };

    updateObj[name] = value;

    // validators.forEach(validator => {
    //   this[validator](value, name);
    // });

    this.setState({ values: updateObj });
  };

  calculateAmount = () => {
    const {
      values: { interestRate, loanTerm, tax, insurance, income, debt },
    } = this.state;

    const annualIncome = income.replace('$', '').replace(/,/g, '');
    const annualInsurance = insurance.replace('$', '').replace(/,/g, '');
    const annualTax = parseInt(tax.replace('$', '').replace(/,/g, ''));
    const monthlyDebt = parseInt(debt.replace('$', '').replace(/,/g, ''));

    let maximumMonthly;

    if (debt) {
      maximumMonthly = (annualIncome * 0.44 - annualTax - annualInsurance) / 12 - monthlyDebt;
    } else {
      maximumMonthly = (annualIncome * 0.44 - annualTax - annualInsurance) / 12;
    }

    const rate = interestRate.replace('%', '').replace(/ /g, '') / 100 / 12;
    const term = loanTerm.replace(' years', '') * 12;

    const numerator = rate * Math.pow(1 + rate, term);
    const denominator = Math.pow(1 + rate, term) - 1;
    const maximumAmount = (maximumMonthly / (numerator / denominator))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    maximumMonthly = maximumMonthly
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    this.setState({
      maximumMonthly,
      maximumAmount,
      calculated: true,
    });

    return [maximumAmount, maximumMonthly];
  };

  render() {
    const { calculated, maximumMonthly, maximumAmount } = this.state;
    return (
      <>
        <CalcTitle>Calculate How Much You Can Borrow</CalcTitle>
        <CalcFieldRow>{this.drawFields(affordBorrow)}</CalcFieldRow>
        <CalcWrapper>
          <CalcButton onClick={() => this.calculateAmount()}>Calculate</CalcButton>
        </CalcWrapper>
        {calculated ? (
          <CalcResultsWrapper>
            <CalcResultsContainer>
              <div>
                <h3>Maximum Monthly Mortgage Payment</h3>
                <h5>${maximumMonthly}</h5>
              </div>
              <div>
                <h3>Maximum Loan Amount</h3>
                <h5>${maximumAmount}</h5>
              </div>
            </CalcResultsContainer>
          </CalcResultsWrapper>
        ) : null}
      </>
    );
  }
}

export default AffordBorrow;
