import React, { PureComponent } from "react";

// import components
import MortgagePayment from "./MortgagePayment";
import IncomeRequired from "./IncomeRequired";
import AffordBorrow from "./AffordBorrow";
import RefinanceSaving from "./RefinanceSaving";

// import styled components
import {
  CalcSectionButton,
  CalcSectionButtonsWrapper,
  CalcSectionContainer,
  CalcSectionWrapper
} from "./styled";

class Calculators extends PureComponent {
  state = {
    activeCalculator: <MortgagePayment />,
    activeClass: "mortgagePayment"
  };

  switchActive = (activeCalculator, activeClass) => {
    this.setState({
      activeCalculator,
      activeClass
    });
  };

  render() {
    return (
      <CalcSectionWrapper id="nav-calculators">
        <CalcSectionContainer>
          <h2>Mortgage Calculators</h2>
          <CalcSectionButtonsWrapper>
            <CalcSectionButton
              className={
                this.state.activeClass === "mortgagePayment" ? "active" : null
              }
              onClick={() =>
                this.switchActive(<MortgagePayment />, "mortgagePayment")
              }
            >
              Mortgage Payment
            </CalcSectionButton>
            <CalcSectionButton
              className={
                this.state.activeClass === "incomeRequired" ? "active" : null
              }
              onClick={() =>
                this.switchActive(<IncomeRequired />, "incomeRequired")
              }
            >
              Income Required
            </CalcSectionButton>
            <CalcSectionButton
              className={
                this.state.activeClass === "affordBorrow" ? "active" : null
              }
              onClick={() =>
                this.switchActive(<AffordBorrow />, "affordBorrow")
              }
            >
              Affordability
            </CalcSectionButton>
            <CalcSectionButton
              className={
                this.state.activeClass === "refinanceSaving" ? "active" : null
              }
              onClick={() =>
                this.switchActive(<RefinanceSaving />, "refinanceSaving")
              }
            >
              Refinance Saving
            </CalcSectionButton>
          </CalcSectionButtonsWrapper>
          {this.state.activeCalculator}
        </CalcSectionContainer>
      </CalcSectionWrapper>
    );
  }
}

export default Calculators;
