import styled from 'styled-components/macro';

// import theme helpers
import { getColor, getFontWeight } from '../../../../util/theme';

const HbGuideSuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  h3 {
    text-transform: uppercase;
  }

  a,
  p {
    color: ${getColor('primaryBlue')};
    font-size: 24px;
  }

  a {
    font-weight: ${getFontWeight('regular')};
    text-decoration: none;

    &:hover {
      color: ${getColor('accentGreen')};
    }
  }
`;

export default HbGuideSuccessWrapper;
