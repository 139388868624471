export function alphaNumericValidator(input, name) {
  // tests for letters, numbers, spaces, and dashes
  // so actually not just alphanumeric, but names also have spaces and dashes sometimes...
  const regexpTest = /^[\w\-\s]+$/;
  const onlyAlphaNumeric = regexpTest.test(input) && input.length > 0;

  const updateObj = { ...this.state };

  // check length > 0 here, as opposed to using the lengthValidator, because all fields with alphaNumeric validator also need to be length > 0
  if (input.length === 0) {
    updateObj.errors[name] =
      "Please fill our this field using letters, numbers, spaces, and dashes";
  } else if (!onlyAlphaNumeric) {
    updateObj.errors[name] =
      "Please enter only letters, numbers, spaces, and dashes";
  } else {
    updateObj.errors[name] = "";
  }

  this.setState({
    errors: updateObj.errors
  });
}

export function emailValidator(input, name) {
  // using this magic thing for a solid email regex http://emailregex.com/
  const regexpTest = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const emailValid = regexpTest.test(input) && input.length > 0;
  const updateObj = { ...this.state };

  if (!emailValid) {
    updateObj.errors[name] = "Please enter a valid email address";
  } else {
    updateObj.errors[name] = "";
  }

  this.setState({
    errors: updateObj.errors
  });
}

export function lengthValidator(input, name) {
  const lengthValid = input.length > 0;
  const updateObj = { ...this.state };

  if (!lengthValid) {
    updateObj.errors[name] = "Please fill out this field";
  } else {
    updateObj.errors[name] = "";
  }

  this.setState({
    errors: updateObj.errors
  });
}

export function telValidator(input, name) {
  // accepts the following phone # formats: 123-456-7890, (123) 456-7890, 123 456 7890, 123.456.7890 (plus a few more variations - check regex)
  const regexpTest = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const telValid = regexpTest.test(input);
  const updateObj = { ...this.state };

  if (!telValid) {
    updateObj.errors[name] = "Please enter a valid phone number";
  } else {
    updateObj.errors[name] = "";
  }

  this.setState({
    errors: updateObj.errors
  });
}

export function dropdownValidator(input, name) {
  const updateObj = { ...this.state };
  const errorMsg = "Please select a loan purpose";

  if (!input) {
    updateObj.errors[name] = errorMsg;
  } else {
    updateObj.errors[name] = "";
  }

  this.setState({
    errors: updateObj.errors
  });
}
