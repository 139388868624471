import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint } from '../../../../util/theme';

const TeamContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr;
  }

  @media (min-width: ${getBreakpoint('lg')}) {
    @supports (display: grid) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
`;

export default TeamContainer;
