import React from "react";
import { loader } from "graphql.macro";
import { Query } from "react-apollo";
import ReactHtmlParser from "react-html-parser";

// import functions
import truncate from "../../../util/truncate";

// import styled components
import { Spinner } from "../../styled";
import {
  ReviewsContainer,
  ReviewsEntry,
  ReviewsRow,
  ReviewsWrapper,
} from "./styled";

// load graphql query
const GET_REVIEWS_QUERY = loader("../../../graphql/GET_REVIEWS_QUERY.graphql");

function Reviews() {
  const staticReviews = [
    {
      id: "2757662645196775401",
      rating: 5,
      comments:
        "Joe took a personal approach to our situation and was able to make it happen when other lenders could not. His rates were as low as it gets and we could not be more pleased. Thanks Joe! ",
      url: "https://birdeye.com/onetrust-home-loans-146315908022173",
      date: "Dec 14, 2021",
      reviewer: {
        firstName: "Adam",
        lastName: "L",
        __typename: "Reviewer",
      },
      __typename: "Review",
    },
    {
      id: "2757413265196755132",
      rating: 5,
      comments:
        "Had to find an alternative mortgage company when a national mortgage company that I had been working with could not find an appraiser. Reached out to Joe, who came through for us. Easy to work with, kept us informed every step of the way, and carried through on everything promised. Would definitely use Joe again and would recommend him to friends and family.",
      url: "https://www.google.com/maps/reviews/data=!4m5!14m4!1m3!1m2!1s109927791881289283875!2s0x8722750fb4c199a1:0x521b6a65ff8fd9bb",
      date: "Dec 14, 2021",
      reviewer: {
        firstName: "Jay",
        lastName: "D",
        __typename: "Reviewer",
      },
      __typename: "Review",
    },
    {
      id: "2741645435195428255",
      rating: 5,
      comments:
        "Joe helped us buy our home, then 2 years later our next home! He has always been prompt, courteous, and very professional. He was available to us 7 days a week and walked us through every step of the process! We loved being able to fill out ALL of our pre-closing docs electronically. Highly recommend!",
      url: "https://birdeye.com/onetrust-home-loans-146315908022173",
      date: "Nov 22, 2021",
      reviewer: {
        firstName: "John",
        lastName: "B",
        __typename: "Reviewer",
      },
      __typename: "Review",
    },
  ];

  const mapReviews = (reviews) => {
    const maxLength = 550;
    let counter = 0;

    return reviews.map((review, i) => {
      const { comments, date, id, rating, reviewer, url } = review;
      let content = "";
      let showUrl = false;
      let stars = "";

      if (!comments) return null;

      if (counter < 3) {
        counter = counter + 1;

        if (comments.length > maxLength) {
          content = truncate(comments, maxLength);
          showUrl = true;
        } else {
          content = comments;
        }

        for (let j = 0; j < rating; j++) {
          stars += "&#9733;";
        }

        return (
          <ReviewsEntry key={id}>
            <p id="reviewBody">{content}</p>
          
            <div>
              <span>{ReactHtmlParser(stars)}</span>
              <p>
                <strong>{`${reviewer.firstName} ${reviewer.lastName
                  .charAt(0)
                  .toUpperCase()}.`}</strong>
              </p>
            </div>
          </ReviewsEntry>
        );
      }
      return null;
    });
  };
  return (
    <ReviewsWrapper id="nav-reviews">
      <ReviewsContainer>
        <h2>What Customers Said About Us</h2>
        
        {/* <Query query={GET_REVIEWS_QUERY}>
          {({ data, error, loading }) => {
            if (loading) return <Spinner />;

            if (error) {
              console.log(error);
              return "Error loading reviews...";
            }

            const {
              reviewsList: { reviews },
            } = data;
            console.log(newReviews);

            return <ReviewsRow>{mapReviews(newReviews)}</ReviewsRow>;
          }}
        </Query> */}
        <ReviewsRow>{mapReviews(staticReviews)}</ReviewsRow>
      </ReviewsContainer>
    </ReviewsWrapper>
  );
}

export default Reviews;
