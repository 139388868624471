import styled from 'styled-components/macro';

// import theme helpers
import { getBreakpoint, getColor } from '../../../../util/theme';

const TeamWrapper = styled.div`
  text-align: center;

  @media (min-width: ${getBreakpoint('lg')}) {
    text-align: left;
  }

  h2 {
    color: ${getColor('primaryBlue')};
    font-weight: bold;
    margin: 5px 0;
  }
`;

export default TeamWrapper;
